<template>
  <div>
    <cookie-consent />
    <hero />

    <v-container
      class="py-5 px-0"
      fluid
    >
      <who-i-am />

      <what-i-can-do-for-you />

      <what-i-know />

      <lets-talk />
    </v-container>
  </div>
</template>

<script>
  export default {
    components: {
      Hero: () => import('@/views/Hero'),
      WhoIAm: () => import('@/views/WhoIAm'),
      WhatICanDoForYou: () => import('@/views/WhatICanDoForYou'),
      WhatIKnow: () => import('@/views/WhatIKnow'),
      LetsTalk: () => import('@/views/LetsTalk'),
    },

    metaInfo () {
      return {
        title: this.$i18n.t('Home.title'),
      }
    },

    data: () => ({
      //
    }),
  }
</script>
